import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "a-big-example-project---176113-tech-salaries-visualized"
    }}>{`A big example project - 176,113 tech salaries visualized`}</h1>
    <Vimeo id={429501613} mdxType="Vimeo" />
    <p>{`We're going to build this:`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/full-dataviz.png",
        "alt": null
      }}></img></p>
    <p>{`An interactive visualization dashboard app with a choropleth map and a
histogram comparing tech salaries to median household income in the area. Users
can filter by year, job title, or US state to get a better view.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/interaction-dataviz.png",
        "alt": null
      }}></img></p>
    <p>{`It's going to be great.`}</p>
    <p>{`At this point, I assume you've used `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` to set up your
environment. Check the
`}<a parentName="p" {...{
        "href": "/local-environment/1"
      }}>{`getting started`}</a>{`
section if you haven't. I'm also assuming you've read the
`}<a parentName="p" {...{
        "href": "/building-blocks/1"
      }}>{`building blocks chapter`}</a>{`.
I'm still going to explain what we're doing, but knowing the basics helps.`}</p>
    <p>{`I suggest you follow along, keep `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` running, and watch your
visualization change in real time as you code. It's rewarding as hell.`}</p>
    <p>{`If you get stuck, you can use my
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-walkthrough-livecode"
      }}>{`react-d3-walkthrough-livecode`}</a>{`
to jump between steps. The
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-walkthrough-livecode/commits/master"
      }}>{`commits`}</a>{`
correspond to the code at the end of each step. Check out the first commit and
run `}<inlineCode parentName="p">{`npm install`}</inlineCode>{` to skip the initial setup.`}</p>
    <p>{`If you want to see how this project evolved over 22 months, check
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/h1b-software-salaries"
      }}>{`the original repo`}</a>{`. The
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/h1b-software-salaries/tree/modern-code"
      }}>{`modern-code`}</a>{`
branch has the code you're about to build.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      